import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClassIcon from '@material-ui/icons/Class';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SharedDataContext from './SharedData.js';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';

import {
  SchoolhouseIconWide,
  DesktopIcon,
  VRIcon,
  CloudIcon,
} from './CustomIcons.js';

import GetFormatCardContent from './GetFormatCardContent.js';
import EstablishingConnectionCardContent from './EstablishingConnectionCardContent.js';
import EstablishingLocalConnectionCardContent from './EstablishingLocalConnectionCardContent.js';
import EstablishingMobileConnectionCardContent from './EstablishingMobileConnectionCardContent.js';
import ReadyToLaunchCardContent from './ReadyToLaunchCardContent.js';
import ReadyToLaunchCloudCardContent from './ReadyToLaunchCloudCardContent.js';
import ReadyToLaunchMobileCardContent from './ReadyToLaunchMobileCardContent.js';
import NeedsInstallCardContent from './NeedsInstallCardContent.js';
import NeedsInstallMobileCardContent from './NeedsInstallMobileCardContent.js';
import RunningLocally from './RunningLocally.js';
import RunningCloud from './RunningCloud.js';
import RunningMobile from './RunningMobile.js';
import RunningLMSTest from './RunningLMSTest.js';
import HelpIcon from '@material-ui/icons/Help';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuItem: {
    width: '75px',
    height: '35px',
    textAlign: 'left',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minHeight: '88px',
    background: '#fff',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    minHeight: '88px',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    minHeight: '88px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  card: {
    marginTop: 40,
    borderRadius: theme.spacing(0.5),
    transition: '0.3s',
    width: '90%',
    overflow: 'initial',
    background: '#ffffff',
  },
  cardContent: {
    paddingTop: '60px',
    paddingBottom: '0px',
    textAlign: 'center',
    width: '100%',
    '@media (max-height:685px)': { paddingTop: 0 },
  },
}));

const modeEnums = {
  GET_FORMAT: 0,
  ESTABLISHING_CONNECTION: 1,
  READY_TO_LAUNCH: 2,
  NEEDS_INSTALL_OR_UPGRADE: 3,
  LAUNCHING_OR_RUNNING: 4,
};
const launchOptionNames = {
  localDesktop: 'Local Desktop',
  localVR: 'Local VR',
  mobileVR: 'Mobile VR',
  cloudStreaming: 'Cloud Streaming',
  testLMS: 'Test LMS',
};
const launchOptionsWithIcons = {
  localDesktop: (
    <>
      <DesktopIcon /> {launchOptionNames['localDesktop']}
    </>
  ),
  localVR: (
    <>
      <VRIcon /> {launchOptionNames['localVR']}
    </>
  ),
  mobileVR: (
    <>
      <VRIcon /> {launchOptionNames['mobileVR']}
    </>
  ),
  cloudStreaming: (
    <>
      <CloudIcon /> {launchOptionNames['cloudStreaming']}
    </>
  ),
  testLMS: (
    <>
      <DesktopIcon /> {launchOptionNames['testLMS']}
    </>
  ),
};

const dataSourceUrl = `${process.env.REACT_APP_SCHOOL_HOUSE_LMS_SUPPORT}/ae21b550-43c9-4d08-8124-25db4c647871`;

const useLiveData = (initialMode) => {
  const [sessionId] = React.useState(
    window.location.search
      ? window.location.search.split('sessionId=')[1].split('&')[0]
      : ''
  ); // TODO Change to use URLSearchParams

  const [mode, setMode] = React.useState(initialMode);

  const [launchOptions, setLaunchOptions] = React.useState({});

  const [launchInputs, setLaunchInputs] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [activeLocalData, setLocalData] = React.useState({});
  const [localAlive, setLocalAlive] = React.useState(false);

  const [activeMobileStatus, setMobileStatus] = React.useState({});
  const [activeMobilePin, setMobilePin] = React.useState("");
  const [activeMobilePinTtl, setMobilePinTtl] = React.useState(0);
  const [activeMobileApplicationData, setMobileApplicationData] = React.useState(undefined);
  const [activeMobileDeviceMutationNeededForSession, setMobileDeviceMutationNeededForSession] =  React.useState(undefined);

  const [activeStreamingData, setStreamingData] = React.useState(null);

  React.useEffect(() => {
    fetch(dataSourceUrl + '/' + sessionId)
      .then((response) => response.json())
      .then((data) => {
        console.log('Got data: ' + JSON.stringify(data));

        if (data.formats) {
          setLaunchInputs(data);
        } else {
          setLaunchInputs(null);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log('Problem in the fetch: ' + e);
      });
  }, [sessionId]);

  return {
    loading,
    current: {
      mode,
      modeEnums,
      launchInputs,
      launchOptions,
      launchOptionNames,
      launchOptionsWithIcons,
      activeLocalData,
      activeMobileStatus,
      activeMobilePin,
      activeMobilePinTtl,
      activeMobileApplicationData,
      activeMobileDeviceMutationNeededForSession, 
      activeStreamingData,
      localAlive,
      sessionId,
    },
    mutate: {
      setMode,
      setLaunchOptions,
      setLocalData,
      setMobileStatus,
      setMobilePin,
      setMobilePinTtl,
      setMobileApplicationData,
      setMobileDeviceMutationNeededForSession,
      setLocalAlive,
      setStreamingData,
    },
  };
};

export default function StudentLanding() {
  const classes = useStyles();
  const cardHeaderStyles = useContainedCardHeaderStyles();
  const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
  const cardHeaderShadowStyles = useFadedShadowStyles();

  const theme = useTheme();

  const liveData = useLiveData(modeEnums.GET_FORMAT);

  const getCurrentContent = () => {
    if (liveData.loading) {
      return null;
    }

    switch (liveData.current.mode) {
      case modeEnums.GET_FORMAT:
        return <GetFormatCardContent className={classes.cardContent} />;
      case modeEnums.ESTABLISHING_CONNECTION:
        if (
          liveData.current.launchOptions.format === 'localDesktop' ||
          liveData.current.launchOptions.format === 'localVR'
        ) {
          return (
            <EstablishingLocalConnectionCardContent
              className={classes.cardContent}
            />
          );
        } 
        else if(liveData.current.launchOptions.format === 'mobileVR')
        {
          return (
            <EstablishingMobileConnectionCardContent
              className={classes.cardContent}
            />
          );
        }
        else if(liveData.current.launchOptions.format === 'testLMS')
        {
          const EstablishingTestLMS=({className})=>
          {
            React.useEffect(() => {
                   liveData.mutate.setMode(
                     liveData.current.modeEnums.LAUNCHING_OR_RUNNING
                   );
            },[]);            
            return "Launching LMS Test"
          }
          return (
            <EstablishingTestLMS
              className={classes.cardContent}
            />
          );
        }
        else 
        {
          return (
            <EstablishingConnectionCardContent
              className={classes.cardContent}
            />
          );
        }
      case modeEnums.READY_TO_LAUNCH:
        if (
          liveData.current.launchOptions.format === 'localDesktop' ||
          liveData.current.launchOptions.format === 'localVR'
        ) {
          return <ReadyToLaunchCardContent className={classes.cardContent} />;
        } 
        else if (liveData.current.launchOptions.format === 'mobileVR')
        {
          return <ReadyToLaunchMobileCardContent className={classes.cardContent} />;    
        }
        else {
          return (
            <ReadyToLaunchCloudCardContent className={classes.cardContent} />
          );
        }
      case modeEnums.NEEDS_INSTALL_OR_UPGRADE:
        if (liveData.current.launchOptions.format === 'mobileVR')
        {
          return <NeedsInstallMobileCardContent className={classes.cardContent} />;    
        }
        else
        {
          return <NeedsInstallCardContent className={classes.cardContent} />;
        }
      case modeEnums.LAUNCHING_OR_RUNNING:
        if (
          liveData.current.launchOptions.format === 'localDesktop' ||
          liveData.current.launchOptions.format === 'localVR'
        ) {
          return <RunningLocally className={classes.cardContent} />;
        }
        else if (liveData.current.launchOptions.format === 'mobileVR')
        {
          return <RunningMobile className={classes.cardContent} />;          
        }
        else if (liveData.current.launchOptions.format === 'testLMS')
        {
          return <RunningLMSTest className={classes.cardContent} />;          
        }
        else {
          return <RunningCloud className={classes.cardContent} />;
        }
      default:
        return 'Error';
    }
  };
  const getCurrentTitle = () => {
    if (liveData.loading) {
      return 'Loading...';
    }
    if (!liveData.current.launchInputs) {
      return 'User Session Expired';
    }
    switch (liveData.current.mode) {
      case modeEnums.GET_FORMAT:
        return 'Select Lesson Format';
      case modeEnums.ESTABLISHING_CONNECTION:
        if (
          liveData.current.launchOptions.format === 'localDesktop' ||
          liveData.current.launchOptions.format === 'localVR'
        ) {
          return 'Establishing Local Connection';
        }
        else if(liveData.current.launchOptions.format === 'mobileVR')
        {
          return 'Establishing Mobile VR Connection';          
        }
        else {
          return 'Establishing Cloud Connection';
        }
      case modeEnums.READY_TO_LAUNCH:
        return 'Ready to Launch';
      case modeEnums.NEEDS_INSTALL_OR_UPGRADE:
        return 'Installing or Upgrading';
      case modeEnums.LAUNCHING_OR_RUNNING:
        if (
          liveData.current.launchOptions.format === 'localDesktop' ||
          liveData.current.launchOptions.format === 'localVR'
        ) {
          return 'Running Locally';
        }
        else if(liveData.current.launchOptions.format === 'mobileVR')
        {
          return 'Available in Mobile VR Headset.\nResults will automatically be recorded.';          
        }
        else if (liveData.current.launchOptions.format === 'testLMS')
        {
          return 'Testing LMS';
        }
        else {
          return 'Running in the Cloud';
        }
      default:
        return 'Error';
    }
  };

  console.log('current', liveData.current);

  return (
    <SharedDataContext.Provider value={liveData}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="default"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: false,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: false,
              })}
            >
              <SchoolhouseIconWide />
            </IconButton>
            <IconButton style={{position:"absolute", right:"10px", top:"15px"}} onClick={()=>{
                window.open("https://docs.disti.com/display/schoolhouse/Student","SchoolhouseStudentDocumentation")
              }
            }><HelpIcon/></IconButton>            

          </Toolbar>
        </AppBar>
        {/*
      <Drawer        
        PaperProps={{style:{background: `${theme.palette.common.black}`}}}
        variant="permanent"
        className={classes.drawerClose}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
            <ListItem style={{marginTop: "50px"}} button key="LessonItem">
              <ListItemIcon>
              <Paper square style={{textAlign:"center", width: "100px", height: "100px"}}>
              <br/>
                <ClassIcon 
                    color="secondary"
                    fontSize="small"/>
                <br/>
                <Typography color="secondary">        
                Lessons
                </Typography>
              </Paper>
              </ListItemIcon>
            </ListItem>
        </List>
        <div style={{color: `${theme.palette.common.white}`, position:"absolute", bottom:"30px", left:"10px", width: "80%", textAlign: "center", fontSize: "11px"}}>
        Virtual training solution managed by
        <br/>
        <br/>
        <DiSTIImage/>
        </div>
      </Drawer>
      */}
        <main className={classes.content}>
          <br />
          <br />
          <div className={classes.toolbar} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card
                className={clsx(classes.card, cardShadowStyles.root)}
                style={{
                  height: 'calc( 100vh - 152px)',
                  width: '100%',
                }}
              >
                <Typography variant="h4" component="h3" color="initial">
                  <CardHeader
                    disableTypography
                    avatar={
                      <ClassIcon
                        style={{ color: `${theme.palette.common.white}` }}
                        fontSize="large"
                      />
                    }
                    className={cardHeaderShadowStyles.root}
                    classes={cardHeaderStyles}
                    style={{
                      color: `${theme.palette.common.white}`,
                      transform: 'translate(0,-20px)',
                      fontSize: '30px',
                      borderRadius: '5px',
                      width: '98%',
                      height: '80px',
                    }}
                    title={getCurrentTitle()}
                  />
                </Typography>
                {liveData.current.launchInputs &&
                !liveData.current.launchInputs.error ? (
                  getCurrentContent()
                ) : (
                  <>
                    <CardContent style={{ textAlign: 'center' }}>
                      <br />
                      <br />
                      <br />
                      {liveData.loading ? (
                        <Typography variant="h3" component="h3">
                          <CircularProgress
                            disableShrink
                            style={{
                              position: 'absolute',
                              top: '300px',
                              // Center it left/right:
                              left: '0px',
                              right: '0px',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                          />
                        </Typography>
                      ) : (
                        <Typography variant="h5" component="h4">
                          Start a new session from the LMS.
                        </Typography>
                      )}
                    </CardContent>
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        </main>
      </div>
    </SharedDataContext.Provider>
  );
}
