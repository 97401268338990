import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { useCloudStreaming } from './CloudStreamingSupport.js';
import { useInterval } from './TimerUtil.js';
import StopIcon from '@material-ui/icons/Stop';
import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  DeviceImage,
} from './CustomIcons.js';

export default function EstablishingConnectionCardContent(params) {
  const liveData = React.useContext(SharedDataContext);

  const [showStopDialog, setShowStopDialog] = React.useState(false);

  const {
    stop: stopStreaming,
    readyToLaunch,
    updateStatus,
    sessionActive,
    wrongSession,
    updateStatusErrorCount,
  } = useCloudStreaming();

  useInterval(
    () => {
      updateStatus();
    },
    (Math.pow(updateStatusErrorCount, 2) + 1) * 4000,
    !updateStatusErrorCount
  );

  React.useEffect(() => {
    if (readyToLaunch && !wrongSession) {
      liveData.mutate.setMode(liveData.current.modeEnums.READY_TO_LAUNCH);
    }
    //eslint-disable-next-line
  }, [readyToLaunch, wrongSession]);

  return (
    <>
      <CardContent {...params}>
        <div style={{ display: 'inline-flex'}}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '0px' }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              {liveData.current.launchInputs.userName} <br />
              {liveData.current.launchInputs.contentName} <br />
              {liveData.current.launchInputs.lessonMode} <br />
              {liveData.current.launchInputs.lessonName} <br />
            </Typography>
            <br />
            {wrongSession ? (
              <>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  You have an existing Cloud Streaming session running
                  <br />
                  with a different lesson.
                  <br />
                  You will need to stop it to continue with this lesson.
                </Alert>
              </>
            ) : null}
            <Button
              disabled={!sessionActive}
              variant="contained"
              color="default"
              size="large"
              style={{ marginRight: '10px', marginTop: '10px', width: '140px', fontSize: '14px' }}
              onClick={() => {
                liveData.mutate.setMode(liveData.current.modeEnums.GET_FORMAT);
              }}
            >
              <ArrowBackIcon size="small" style={{ marginRight: '10px' }} />
              {sessionActive ? 'Back' : 'Launching...'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
              disabled={!sessionActive}
              onClick={() => {
                setShowStopDialog(true);
              }}
            >
              <StopIcon size="small" style={{ marginRight: '10px' }} /> Stop
            </Button>
          </div>
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-start', height: '15em' }}
          />
        </div>
        <Dialog
          open={showStopDialog}
          onClose={() => setShowStopDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Stop Cloud Streaming?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Stopping will loose any progress and will not be scored.
              <br />
              Are you sure you want to stop?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowStopDialog(false);
              }}
              color="primary"
            >
              No
            </Button>
            <Button
              onClick={() => {
                setShowStopDialog(false);
                stopStreaming();
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </>
  );
}
