import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import SharedDataContext from './SharedData.js';
import Button from '@material-ui/core/Button';
import {
  useMobileLaunchSupport,
  useInterval,
} from './MobileAccessSupport.js';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import unknownHeadsetImage from '../images/unknown-headset.jpg';

import appImage from 'images/icon@2x.png'
import {
  DeviceImage,
} from './CustomIcons.js';

const NumberThing = ({number})=>{
  return <span style={{position:"absolute",top:"0",left:"0", background:"lightgrey", width:"2em", height:"2em", borderRadius:"50%", color:"black"}}>{number}</span>
}
export default function EstablishingMobileConnectionCardContent(params) {
  const liveData = React.useContext(SharedDataContext);
  const mls = useMobileLaunchSupport();
  const [needsPinExchange, setNeedsPinExchange] = React.useState(undefined);

  const status = liveData.current.activeMobileStatus;

  React.useEffect(()=>{
    if (status && status.deviceLinked !== undefined)
    {
      setNeedsPinExchange(!status.deviceLinked);      
    }
    //eslint-disable-next-line
  },[status?.deviceLinked]);

  React.useEffect(()=>{
    if (!liveData.current.activeMobilePin && needsPinExchange===true)
    {
      mls.createNewPin();
    }
    //eslint-disable-next-line
  },[liveData?.current?.activeMobilePin, needsPinExchange]);

  React.useEffect(() => {
    if (status) {
      if (status.deviceLinked)
       {
         liveData.mutate.setMode(
           liveData.current.modeEnums.NEEDS_INSTALL_OR_UPGRADE
         );
      }
    }
    //eslint-disable-next-line
  }, [status?.deviceLinked]);

  useInterval(
    () => {
      mls.checkDeviceStatus();
    },
    2000,
    true
  );

  return (
    <>
      <CardContent {...params} style={{topPadding:0}}>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '0px' }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              {liveData.current.launchInputs.userName} <br />
              {liveData.current.launchInputs.contentName} <br />
              {liveData.current.launchInputs.lessonMode} <br />
              {liveData.current.launchInputs.lessonName} <br />
            </Typography>
            <br />
            {(needsPinExchange && liveData.current.activeMobilePin) ?<>
            <div style={{display: "inline-flex", gap:"15px", width:"50em"}}>
              <Paper elevation={6} style={{flex:"1 1 25%", textAlign:"center", position:"relative", paddingTop:"2em"}}>
              <Typography variant="subtitle2">
                
                <NumberThing number={1}/>Put on your Mobile VR headset
                </Typography>
                <DeviceImage src={unknownHeadsetImage}
                style={{ marginLeft: '20px', maxWidth:"75%"}}
              />
                </Paper>
                <Paper elevation={6} style={{flex:"1 1 25%", textAlign:"center", position:"relative", paddingTop:"2em"}}>
                <Typography variant="subtitle2">
                <NumberThing number={2}/>Open the Schoolhouse Mobile Connector application in the headset
                  </Typography>
                  <br/>
                  <img src={appImage} alt="App Logo" style={{maxWidth:"75%"}}/>
                  </Paper>
                  <Paper elevation={6} style={{flex:"1 1 25%", textAlign:"center", position:"relative", paddingTop:"2em"}}>
                    <NumberThing number={3}/> 
                    <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                    <Typography variant="subtitle2">
                    Enter this temporary PIN:<br/>
            <span style={{fontVariantNumeric: "tabular-nums", fontSize: "2rem"}}>{liveData.current.activeMobilePin}</span><br/>
            into the headset<br/>
            </Typography>
            {liveData.current.activeMobilePinTtl?
            <i>expires in {Math.floor(liveData.current.activeMobilePinTtl - (Date.now()/1000))} seconds<br/></i>
            :null}
            <br/>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginRight: '10px', marginTop: '10px', width: '140px', fontSize: '14px' }}
              onClick={() => {
                mls.createNewPin();
              }}
            >              
              <ReplayIcon size="small" style={{ marginRight: '10px' }} /> New PIN
            </Button>
            </div>
            </Paper>
            </div>
            </>:<i>Checking for headset linkage...</i>}
            <br/>
            <br/>
            <Button
              disabled={false}
              variant="contained"
              color="default"
              size="large"
              style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
              onClick={() => {
                liveData.mutate.setMode(liveData.current.modeEnums.GET_FORMAT);
              }}
            >
              <ArrowBackIcon size="small" style={{ marginRight: '10px' }} />
              {'Back'}
            </Button>
          </div>
        </div>
      </CardContent>
    </>
  );
}
