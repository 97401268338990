import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DesktopIconSvg from '../images/Desktop-Icon.svg';
import VRIconSvg from '../images/VR-Icon.svg';
import CloudIconSvg from '../images/Cloud-Icon.svg';

import deviceImage from '../images/device_001.png';
import distiImage from '../images/DiSTI-Logo-Side-Nav-White-sm.png';
import schoolhouseIconWideImage from '../images/Schoolhouse-Logo-Horizontal-Md.png';
import SharedDataContext from './SharedData.js';
const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: '75px',
    height: '35px',
    textAlign: 'left',
  },
}));

const DeviceImage = (params) => {
  const { current } = React.useContext(SharedDataContext);
  return (
      <img
        src={
          `https://lmsintegration.${process.env.REACT_APP_SCHOOL_HOUSE_TENANT}.disti.training/b883b484-5f69-4d52-a6d0-4da6a6700887/${current.launchInputs.contentId}`
        }
        {...params}
        onError={(ev)=> {
          ev.target.src = (params.src || deviceImage);
        }}
        alt="Device"
      />
  );
};

const DesktopIcon = (params) => {
  const classes = useStyles();
  return (
    <div {...params} className={classes.menuItem}>
      <img src={DesktopIconSvg} alt="Desktop"/>
    </div>
  );
};
const VRIcon = (params) => {
  const classes = useStyles();
  return (
    <div {...params} className={classes.menuItem}>
      <img src={VRIconSvg} alt="VR"/>
    </div>
  );
};
const CloudIcon = (params) => {
  const classes = useStyles();
  return (
    <div {...params} className={classes.menuItem}>
      <img src={CloudIconSvg} alt="Cloud"/>
    </div>
  );
};
const DiSTIImage = () => {
  return <img src={distiImage} alt="DiSTI Logo" />;
};
const SchoolhouseIconWide = () => {
  return <img src={schoolhouseIconWideImage} alt="Logo" />;
};

export {
  DiSTIImage,
  SchoolhouseIconWide,
  DeviceImage,
  DesktopIcon,
  VRIcon,
  CloudIcon,
};
