import React from 'react';
import SharedDataContext from './SharedData.js';

const lambdaUrlBase = `${process.env.REACT_APP_SCHOOL_HOUSE_LMS_SUPPORT}`
const actionGetAppStreamStatusForUser = 'ef6b1af0-1f0a-4334-87ac-b04665ce2a3e'//sessionId
const actionStopStreamingSessionForUser = 'cba91723-05af-41fc-ab8e-0ba73f951b28'//sessionId

const useCloudStreaming = ()=>
{
    const region = window.location.href && window.location.href.search(".disti.training") !== -1 ? window.location.href.split('https://')[1].split('.')[0] : 'us-east-1';

    const [updateStatusErrorCount,setUpdateStatusErrorCount] = React.useState(0)
    const liveData = React.useContext(SharedDataContext);     
    const sessionId = liveData.current.sessionId;
    
    const launch = async ()=>{
        window.open(`${process.env.REACT_APP_SCHOOL_HOUSE_LMS_SUPPORT}/9628d590-9b4b-4e0c-b4e8-3aae58d4b956/${sessionId}?region=${region}`, 'StreamingSession')        
    }
       
    const d = liveData.current.activeStreamingData
    const wrongSession = d && d.existingSession && d.correctSession === false
    
    let readyToLaunch = d && (!d.existingSession || 
                        (d && d.existingSession && d.correctSession)) && !wrongSession;
           
    const sessionActive = d && d.active;
    
    const updateStatus = ()=>
    {
        fetch(lambdaUrlBase +"/"+actionGetAppStreamStatusForUser+"/"+sessionId)
            .then(response=> response.json())
            .then(data =>{
              console.log("Got data: "+JSON.stringify(data))
        
              if (updateStatusErrorCount)
              {
                  setUpdateStatusErrorCount(0)
              }
              liveData.mutate.setStreamingData( data )
            })
            .catch( e=> {
                setUpdateStatusErrorCount(updateStatusErrorCount + 1)
                console.log("Problem getting status:",e)
            })
                
    }
    const stop = ()=>
    {
        fetch(lambdaUrlBase +"/"+actionStopStreamingSessionForUser+"/"+sessionId)        
    }
    return { launch, stop, readyToLaunch, updateStatus, sessionActive, wrongSession, updateStatusErrorCount }  
}

export { useCloudStreaming }