import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import LinearProgress from '@material-ui/core/LinearProgress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  useLaunchSupportApplication,
  useInterval,
} from './LocalAccessSupport.js';

import {
  DeviceImage,
} from './CustomIcons.js';

export default function NeedsInstallCardContent(params) {
  const liveData = React.useContext(SharedDataContext);
  const lsa = useLaunchSupportApplication();

  React.useEffect(() => {
    if (
      liveData.current.activeLocalData.installed &&
      liveData.current.activeLocalData.upToDate
    ) {
      liveData.mutate.setMode(liveData.current.modeEnums.READY_TO_LAUNCH);
    }
  }, [liveData]);

  useInterval(
    () => {
      lsa.sendCommand({ command: 'check' });
    },
    2000,
    true
  );

  return (
    <>
      <CardContent {...params}>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '0px' }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              <br />
              Need to install "{liveData.current.launchInputs.contentName}"
              <br />
            </Typography>
            <br />
            <div style={{ display: 'inline-flex' }}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginRight: '10px', marginTop: '10px', width: '140px', fontSize: '14px' }}
                  disabled={
                    !(
                      liveData.current.localAlive &&
                      liveData.current.activeLocalData &&
                      (!liveData.current.activeLocalData.upToDate ||
                        !liveData.current.activeLocalData.installed)
                    ) || liveData.current.activeLocalData.installing
                  }
                  onClick={() => {
                    lsa.sendCommand({
                      command: 'installById',
                      contentId: liveData.current.launchInputs.contentId,
                    });
                  }}
                >
                  <OpenInNewIcon size="small" style={{ marginRight: '10px' }} />
                  {liveData.current.activeLocalData.installed
                    ? 'Upgrade'
                    : liveData.current.activeLocalData.installing
                    ? 'Installing'
                    : 'Install'}
                </Button>
                {liveData.current.activeLocalData.installing ? (
                  <LinearProgress
                    style={{ width: '140px' }}
                    color="secondary"
                    variant="determinate"
                    value={Number(
                      liveData.current.activeLocalData.installingProgress *
                        100.0
                    ).toFixed(2)}
                  />
                ) : null}
              </div>

              {liveData.current.activeLocalData.installing ? (
                <Button
                  variant="contained"
                  color="default"
                  size="large"
                  style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
                  onClick={() => {
                    lsa.sendCommand({
                      command: 'cancelInstallById',
                      contentId: liveData.current.launchInputs.contentId,
                    });
                  }}
                >
                  <CancelIcon size="small" style={{ marginRight: '10px' }} />
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="default"
                  size="large"
                  style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
                  onClick={() => {
                    liveData.mutate.setMode(
                      liveData.current.modeEnums.GET_FORMAT
                    );
                  }}
                >
                  <ArrowBackIcon size="small" style={{ marginRight: '10px' }} />{' '}
                  Back
                </Button>
              )}
            </div>
          </div>
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-start', height: '15em' }}
          />
        </div>
      </CardContent>
    </>
  );
}
