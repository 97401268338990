import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import Button from '@material-ui/core/Button';
import {
  useLaunchSupportApplication,
  useInterval,
} from './LocalAccessSupport.js';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import popupImage from 'images/launch-support-application-confirm-image.png';

import {
  DeviceImage,
} from './CustomIcons.js';


const lambdaUrlBase = `${process.env.REACT_APP_SCHOOL_HOUSE_LMS_SUPPORT}`;
const downloadLsaAction = '11ecd3af-24e1-4cbb-ba17-17ca0f2ae964'; //sessionId

export default function EstablishingLocalConnectionCardContent(params) {
  const liveData = React.useContext(SharedDataContext);
  const lsa = useLaunchSupportApplication();

  const [didPopup, setDidPopup] = React.useState(false);

  React.useEffect(() => {
    if (liveData.current.localAlive) {
      if (
        liveData.current.activeLocalData.installed &&
        liveData.current.activeLocalData.upToDate
      ) {
        liveData.mutate.setMode(liveData.current.modeEnums.READY_TO_LAUNCH);
      } else {
        liveData.mutate.setMode(
          liveData.current.modeEnums.NEEDS_INSTALL_OR_UPGRADE
        );
      }
    }
  }, [liveData]);

  useInterval(
    () => {
      lsa.sendCommand({ command: 'check' });
    },
    2000,
    true
  );

  React.useEffect(() => {
    if (!didPopup && lsa.timedOut) {
      lsa.popupLaunch();
      setDidPopup(true);
    }
  }, [lsa, didPopup]);

  const downloadLsaLink = lambdaUrlBase + '/' + downloadLsaAction + '/false';

  return (
    <>
      <CardContent {...params} >
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '0px' }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              {liveData.current.launchInputs.userName} <br />
              {liveData.current.launchInputs.contentName} <br />
              {liveData.current.launchInputs.lessonMode} <br />
              {liveData.current.launchInputs.lessonName} <br />
            </Typography>
            <br />
            {lsa.timedOut ? (
              <>
                <Alert severity="info">
                  <span style={{float:"left"}}>When you see this popup, select Open.</span>
                    <img alt="Launch popup prompt" style={{margin:"10px", float:"right",height:"100px"}} src={popupImage}/>
                </Alert>
                
              </>
            ) : null}

            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginRight: '10px', marginTop: '10px', width: '140px', fontSize: '14px' }}
              disabled={!lsa.timedOut}
              onClick={() => {
                lsa.popupLaunch();
              }}
            >
              <ReplayIcon size="small" style={{ marginRight: '10px' }} /> Retry
            </Button>
            <Button
              disabled={false}
              variant="contained"
              color="default"
              size="large"
              style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
              onClick={() => {
                liveData.mutate.setMode(liveData.current.modeEnums.GET_FORMAT);
              }}
            >
              <ArrowBackIcon size="small" style={{ marginRight: '10px' }} />
              {'Back'}
            </Button>
            {lsa.timedOut && ((Date.now() - lsa.timedOutTime) > 4000)?
                <Alert style={{marginTop:"10px"}} severity="info">
                  
                  If this is the first time using Schoolhouse on this machine, you can download it {' '}
                  <a
                    href={downloadLsaLink}
                    download="launch_support_application_installer.exe"
                  >
                    here
                  </a>
                  .
                </Alert>
            :null}
          </div>
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-start', height: '15em' }}
          />
        </div>
      </CardContent>
    </>
  );
}
