import React from 'react';
import SharedDataContext from './SharedData.js';

import { useTimeout, useInterval } from './TimerUtil.js';

const tenantSpecificPort = process.env.REACT_APP_SCHOOL_HOUSE_TENANT_SPECIFIC_LOCAL_PORT
const tenant = process.env.REACT_APP_SCHOOL_HOUSE_TENANT

const triggerWithIFrame = () => {
  var ifrm = document.createElement('iframe');
  ifrm.setAttribute('src', 'distilsa-' + tenant + '://serve');
  ifrm.style.width = '1px';
  ifrm.style.height = '1px';
  ifrm.style.display = 'none';
  ifrm.onload = () => {
    console.log('IFrame loaded');
    //document.body.delete(ifrm);
  };
  ifrm.onerror = () => {
    console.log('IFrame error');
  };
  document.body.appendChild(ifrm);
};

async function fetchWithTimeout(resource, options) {
  const { timeout = 4000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

const host = 'localhost';
const basePath = 'http://' + host + ':' + tenantSpecificPort + '/';

const fetchFromLocal = async ({
  actionName,
  sessionId,
  format,
  onSuccess,
  onFailure,
}) => {
  await fetchWithTimeout(
    basePath +
    GuidsByActionName[actionName] +
    '/' +
    sessionId +
    (format ? '/' + format : ''),
    { cache: 'no-store' }
  )
    .then((response) => response.json())
    .then((data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((e) => {
      if (onFailure) {
        onFailure(e);
      }
    });
};

const GuidsByActionName = {
  check: '5319e646-49a1-47d1-8c40-f032e6a9ef5c',
  upgradeById: '6e2bcdbc-85cb-4687-97cb-251b08a45ea8',
  installById: '32fa0e45-4064-434e-85d1-907d33136e45',
  launchById: 'b0f60fc6-5fda-4b3d-b156-0ba7bd39f8e8',
  cancelLaunchById: 'fcf3613b-672b-4b7f-b352-33b8a0cced10',
  runStatusById: '808524c7-4897-45e6-975a-21d2370d3fe6',
  cancelInstallById: '71764e63-46a3-40e7-a794-ac6b034f3adf',
  killLSA: '103df3f8-5d03-4994-891d-93b9d75d19e4',
};

const useLaunchSupportApplication = () => {
  const liveData = React.useContext(SharedDataContext);
  const [checking, setChecking] = React.useState(0);
  const [installing, setInstalling] = React.useState(0);
  const [launching, setLaunching] = React.useState(0);
  const [timedOut, setTimedOut] = React.useState(false);
  const [timedOutTime, setTimedOutTime] = React.useState(null)

  const active = checking || installing || launching;

  const [, sendCommand] = React.useReducer((state, action) => {
    switch (action.command) {
      case 'check':
        setChecking(true);
        fetchFromLocal({
          actionName: action.command,
          sessionId: liveData.current.sessionId,
          onSuccess: (data) => {
            setChecking(false);
            liveData.mutate.setLocalAlive(true);
            liveData.mutate.setLocalData(data);
          },
          onFailure: () => {
            setChecking(false);
            liveData.mutate.setLocalAlive(false);
            if (!timedOut) {
              // Record the moment we realized we timedout
              setTimedOutTime(Date.now())
            }
            setTimedOut(true);
          },
        });
        break;
      case 'upgradeById':
      case 'installById':
        setInstalling(true);
        fetchFromLocal({
          actionName: action.command,
          sessionId: liveData.current.sessionId,
          onSuccess: (upgradeData) => {
            setInstalling(false);
          },
          onFailure: () => {
            setInstalling(false);
          },
        });
        break;
      case 'cancelInstallById':
        fetchFromLocal({
          actionName: action.command,
          sessionId: liveData.current.sessionId,
          onSuccess: (upgradeData) => {
            setInstalling(false);
          },
          onFailure: () => {
            setInstalling(false);
          },
        });
        break;
      case 'launchById':
      case 'cancelLaunchById':
        setLaunching(action.contentId);
        fetchFromLocal({
          actionName: action.command,
          sessionId: liveData.current.sessionId,
          format: liveData.current.launchOptions.format,
          onSuccess: (data) => {
            setLaunching(false);
          },
          onFailure: () => setLaunching(false),
        });
        break;
      case 'killLSA':
        fetchFromLocal({
          actionName: action.command,
          sessionId: liveData.current.sessionId,
          format: liveData.current.launchOptions.format,
        });
        break;
      default:
        break;
    }

    return false;
  }, false);

  const popupLaunch = () => {
    triggerWithIFrame();
  };
  return {
    sendCommand,
    active,
    checking,
    installing,
    launching,
    timedOut,
    timedOutTime,
    popupLaunch,
  };
};

export { useLaunchSupportApplication, useInterval, useTimeout };
