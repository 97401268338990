import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import Button from '@material-ui/core/Button';

import axios from 'axios';

export default function RunningLMSTest(params) {
  const [lastResult, setLastResult] = React.useState("");

  const liveData = React.useContext(SharedDataContext);
  const statusString = ()=>
  {
      return `Ready to report results.`
  }

  const reportResult = async (score /*0.0=>100.0*/)=>
  {  
    const sessionId = liveData.current.sessionId;
    const progressData = {
        "final": true,
        "score": score
        }
  
    const actionUploadStatus = 'ef8189e2-936e-44d8-957a-a8c1aeea4b99'
    const urlToUploadStatus = `https://lmsintegration.${process.env.REACT_APP_SCHOOL_HOUSE_TENANT}.disti.training/${actionUploadStatus}/${sessionId}`
  
  
    const data = JSON.stringify(progressData)
    console.log("reporting data:",data)    
  
    const resultResult = await axios.post(urlToUploadStatus, data)
    console.log("Got resultResult:",resultResult);
    return resultResult?.data;
  }
  
  const ReportButton =({score})=>
  {
    return <Button
      variant="contained"
      color="primary"
      size="large"
      style={{ marginRight: '10px', marginTop: '10px', width: '140px', fontSize: '14px' }}
      disabled={false}
      onClick={ async () => {
        // Report with the session id the results
        setLastResult(`Reporting ${score}% ...`)
        try {
          const result = await reportResult(score);                        
    
          setLastResult(`Successfully Reported ${score}%`)

          if (result.redirect)
          {
            window.location.href = result.redirect;
          }
      
        }
        catch(e)
        {
          setLastResult("Error: " + JSON.stringify(e))
        }
      }}
    >
      {`Report ${score}%`}            
    </Button>
  }
  return (
    <>
      <CardContent {...params}>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '10%', flex:"0 1 50%" }}>
            <Typography variant="h6" component="h6">

              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              {liveData.current.launchInputs.userName} <br />
              {liveData.current.launchInputs.contentName} <br />
              {liveData.current.launchInputs.lessonMode} <br />
              {liveData.current.launchInputs.lessonName} <br />
            </Typography>
          </div>
          {/* <span style={{float: 'right'}}>
          {liveData.current.activeMobileApplicationData?.mediaUrl?
          <DeviceImage src={liveData.current.activeMobileApplicationData.mediaUrl}
            style={{ marginLeft: '20px', alignSelf: 'flex-start', width: '20%' }}
          />
          :
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-start', width: '20%' }}
          />}
                      <CheckIcon style={{alignSelf: 'flex-middle', width:"10%", height:"initial", color:"green", verticalAlign: "top"}}/>
          {liveData.current.activeMobileStatus?.deviceDetails?.deviceModel?.media?.url ?
          <DeviceImage src={liveData.current.activeMobileStatus.deviceDetails.deviceModel.media.url}
            style={{ marginLeft: '20px', alignSelf: 'flex-end', width: '20%' }}
          />
          :
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-end', width: '20%' }}
          />}
          </span>
          */}
          
        </div> 
        <Typography variant="body1">
        
        <br /><b>{statusString()}</b><br/>
        <ReportButton score={100}/>
        <ReportButton score={98}/>
        <ReportButton score={95}/>
        <ReportButton score={93}/>
        <ReportButton score={50}/>
        <ReportButton score={0}/>
          <br />
          <br /><b>{lastResult}</b><br/>

        </Typography> 

      </CardContent>
    </>
  );
}
