import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import {
  DeviceImage,
} from './CustomIcons.js';

export default function RunningMobile(params) {
  const liveData = React.useContext(SharedDataContext);
  
  const launchReturnURL = liveData.current?.activeMobileStatus?.launchReturnURL;
  const appName = liveData.current?.activeMobileApplicationData?.title || "The Application"
  const statusString = ()=>
  {
      return `Launch ${appName} in the headset at any time.`
  }
  return (
    <>
      <CardContent {...params}>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '10%', flex:"0 1 50%" }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              {liveData.current.launchInputs.userName} <br />
              {liveData.current.launchInputs.contentName} <br />
              {liveData.current.launchInputs.lessonMode} <br />
              {liveData.current.launchInputs.lessonName} <br />
            </Typography>
          </div>
          <span style={{float: 'right'}}>
          {liveData.current.activeMobileApplicationData?.mediaUrl?
          <DeviceImage src={liveData.current.activeMobileApplicationData.mediaUrl}
            style={{ marginLeft: '20px', alignSelf: 'flex-start', width: '20%' }}
          />
          :
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-start', width: '20%' }}
          />}
                      <CheckIcon style={{alignSelf: 'flex-middle', width:"10%", height:"initial", color:"green", verticalAlign: "top"}}/>
          {liveData.current.activeMobileStatus?.deviceDetails?.deviceModel?.media?.url ?
          <DeviceImage src={liveData.current.activeMobileStatus.deviceDetails.deviceModel.media.url}
            style={{ marginLeft: '20px', alignSelf: 'flex-end', width: '20%' }}
          />
          :
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-end', width: '20%' }}
          />}
          </span>
        </div>
        <Typography variant="body1">
        <br /><br/>
              <b>{statusString()}</b>
              <br/>
              {launchReturnURL? <Button
              variant="contained"
              color="default"
              size="large"
              style={{ marginRight: '10px', marginTop: '10px', width: '140px', fontSize: '14px' }}
              onClick={() => {
                window.location.href = launchReturnURL
              }}
            >Okay</Button>: "You can close this window any time"}
        </Typography>

      </CardContent>
    </>
  );
}
