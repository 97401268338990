import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { useCloudStreaming } from './CloudStreamingSupport.js';
import { useInterval } from './TimerUtil.js';

import {
  DeviceImage,
} from './CustomIcons.js';

export default function ReadyToLaunchCloudCardContent(params) {
  const liveData = React.useContext(SharedDataContext);

  const {
    launch: launchStreamingUrl,
    readyToLaunch,
    updateStatus,
    sessionActive,
    updateStatusErrorCount,
  } = useCloudStreaming();

  useInterval(
    () => {
      updateStatus();
    },
    (Math.pow(updateStatusErrorCount, 2) + 1) * 2000,
    !updateStatusErrorCount
  );

  React.useEffect(() => {
    if (sessionActive) {
      liveData.mutate.setMode(liveData.current.modeEnums.LAUNCHING_OR_RUNNING);
    }
    //eslint-disable-next-line
  }, [sessionActive]);

  return (
    <>
      <CardContent {...params}>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '0px' }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>

            <Typography variant="body1">
              {liveData.current.launchInputs.userName} <br />
              {liveData.current.launchInputs.contentName} <br />
              {liveData.current.launchInputs.lessonMode} <br />
              {liveData.current.launchInputs.lessonName} <br />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginRight: '10px', marginTop: '10px', width: '140px', fontSize: '14px' }}
              disabled={!readyToLaunch}
              onClick={() => {
                // We might need to launch directly from a mouse handler to avoid popup blocker issues
                launchStreamingUrl();

                // Jump to the launching page
                liveData.mutate.setMode(
                  liveData.current.modeEnums.LAUNCHING_OR_RUNNING
                );
              }}
            >
              <OpenInNewIcon size="small" style={{ marginRight: '10px' }} />{' '}
              Launch
            </Button>
            <Button
              variant="contained"
              color="default"
              size="large"
              style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
              onClick={() => {
                //setCurrentMode( GET_FORMAT );
                liveData.mutate.setMode(liveData.current.modeEnums.GET_FORMAT);
              }}
            >
              <ArrowBackIcon size="small" style={{ marginRight: '10px' }} />{' '}
              Back
            </Button>
          </div>
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-start', height: '15em' }}
          />
        </div>
      </CardContent>
    </>
  );
};
