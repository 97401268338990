import React from 'react';
import SharedDataContext from './SharedData.js';

import { useTimeout, useInterval } from './TimerUtil.js';

//const tenant = process.env.REACT_APP_SCHOOL_HOUSE_TENANT

const baseBase = `${process.env.REACT_APP_SCHOOL_HOUSE_LMS_SUPPORT}`

const GuidsByActionName = {
  createNewPin: '342e85ca-a763-44b3-ab9f-99790b73b021', //sessionId
  connectDeviceWithPin: 'd830e6c6-6e43-476d-9ed2-e72dc2a228ec', //pin/deviceId
  getMobileDeviceStatus: '3afa9056-5b81-4ea9-be8e-41ddfad633e9', //sessionId
  getApplicationDataBySession: '5729ce6e-dbac-47e8-8714-059aa7262e71', //sessionId
  mutateDeviceForSession: '32ac064a-2adb-4cf5-a28a-872cd1022490', //sessionId
  getDeviceMutationNeededForSession: '2a103231-cb04-4542-929f-829cb6113e67', //sessionId
};

const useMobileLaunchSupport = () => {
  const liveData = React.useContext(SharedDataContext);
  const [checking, setChecking] = React.useState(0);
  //const [deviceStatus, setDeviceStatus] = React.useState(null);
  //const [currentPin, setCurrentPin] = React.useState("");
  
  const createNewPin = async ()=>{
    const {pin, ttl} = await fetch(baseBase +"/"+GuidsByActionName.createNewPin+"/"+liveData.current.sessionId).then(response=>response.json());
    liveData.mutate.setMobilePin(pin);
    liveData.mutate.setMobilePinTtl(ttl);
  }
  const checkDeviceStatus = async ()=>{
    setChecking(true);
    try
    {
      const status = await fetch(baseBase +"/"+GuidsByActionName.getMobileDeviceStatus+"/"+liveData.current.sessionId).then(response=>response.json());
      //setDeviceStatus(status);
      liveData.mutate.setMobileStatus(status);
    }
    catch(e)
    {
      console.log("Failed to get status");
    }
    setChecking(false);
  }
  const getApplicationData = async ()=>{
    try
    {
      const data = await fetch(baseBase +"/"+GuidsByActionName.getApplicationDataBySession+"/"+liveData.current.sessionId).then(response=>response.json());
      liveData.mutate.setMobileApplicationData(data);
    }
    catch(e)
    {
      console.log("Failed to get application data");
    }
  }
  const getDeviceMutationNeededForSession = async()=>
  {
    try
    {
      const result = await fetch(baseBase +"/"+GuidsByActionName.getDeviceMutationNeededForSession+"/"+liveData.current.sessionId).then(response=>response.json());
      if (!result.success)
      {
        console.log("Problem with getDeviceMutationNeededForSession:",result);
      }
      else
      {
        liveData.mutate.setMobileDeviceMutationNeededForSession(result.needed===true);
      }
    }
    catch(e)
    {
      console.log("Problem in getDeviceMutationNeededForSession:",e);
    }
  }
  const mutateDeviceForSession = async()=>{
    try
    {
      const result = await fetch(baseBase +"/"+GuidsByActionName.mutateDeviceForSession+"/"+liveData.current.sessionId).then(response=>response.json());
      if (!result.success)
      {
        console.log("Problem with mutateDeviceForSession:",result);
      }
    }
    catch(e)
    {
      console.log("Problem in mutateDeviceForSession:",e);
    }
  }
  

  return {    
    checkDeviceStatus,
    createNewPin,
    getApplicationData,
    getDeviceMutationNeededForSession,
    mutateDeviceForSession,
    checking,    
  };
};

export { useMobileLaunchSupport, useInterval, useTimeout };
