import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import {
  useMobileLaunchSupport,
} from './MobileAccessSupport.js';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
  DeviceImage,
} from './CustomIcons.js';

export default function ReadyToLaunchCardContent(params) {
  const liveData = React.useContext(SharedDataContext);
  const mls = useMobileLaunchSupport();

  React.useEffect(()=>
  {
    // Nothing to do here for mobile, so continue
    liveData.mutate.setMode(liveData.current.modeEnums.LAUNCHING_OR_RUNNING)
  },[liveData]);


  return (
    <>
      <CardContent {...params}>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '0px' }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              {liveData.current.launchInputs.userName} <br />
              {liveData.current.launchInputs.contentName} <br />
              {liveData.current.launchInputs.lessonMode} <br />
              {liveData.current.launchInputs.lessonName} <br />
            </Typography>
            <br />
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                marginRight: '10px',
                marginTop: '10px',
                width: '140px',
                fontSize: '14px',
              }}
              disabled={
                !(
                  liveData.current.localAlive &&
                  liveData.current.activeLocalData &&
                  liveData.current.activeLocalData.upToDate &&
                  liveData.current.activeLocalData.installed
                )
              }
              onClick={() => {
                mls.sendCommand({
                  command: 'launchById',
                  contentId: liveData.current.launchInputs.contentId,
                });
              }}
            >
              <OpenInNewIcon size="small" style={{ marginRight: '10px' }} />{' '}
              Launch
            </Button>
            <Button
              variant="contained"
              color="default"
              size="large"
              style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
              onClick={() => {
                //setCurrentMode( GET_FORMAT );
                liveData.mutate.setMode(liveData.current.modeEnums.GET_FORMAT);
              }}
            >
              <ArrowBackIcon size="small" style={{ marginRight: '10px' }} />{' '}
              Back
            </Button>
          </div>
          <DeviceImage
            style={{
              marginLeft: '20px',
              alignSelf: 'flex-start',
              height: '15em',
            }}
          />
        </div>
        <Tooltip title="Shutdown Launch Support Application">
          <IconButton
            style={{ position: 'absolute', right: '10px', bottom: '10px' }}
            onClick={() => {
              mls.sendCommand({ command: 'killLSA' });
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </CardContent>
    </>
  );
}
