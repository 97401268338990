import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import SharedDataContext from './SharedData.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  useMobileLaunchSupport,
  useInterval,
} from './MobileAccessSupport.js';

import {
  DeviceImage,
} from './CustomIcons.js';

const InstallStatus = (importantThing = {})=>
{
  const liveData = React.useContext(SharedDataContext);
  
  //eslint-disable-next-line
  if (liveData.current.activeMobileApplicationData === undefined)
  {
    return <i>Loading...</i>
  }
  if (!liveData.current.activeMobileApplicationData)
  {
    return <i>Error: Assigned application is not available on the device.</i>
  }

  const details = liveData.current.activeMobileStatus?.installSummary?.status?.details || undefined;

  const contentName = liveData.current.activeMobileStatus?.installSummary?.contentName || "";

  if (details)
  {
    return <span><b>{contentName}: </b><i>{details}</i></span>
  }
  return <b><i>{contentName}</i> automatically installing</b>
}
export default function NeedsInstallMobileCardContent(params) {
  const liveData = React.useContext(SharedDataContext);
  const mls = useMobileLaunchSupport();
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [readyToContinue, setReadyToContinue] = React.useState(false);

  const appName = liveData.current?.activeMobileApplicationData?.title || "The Application"

  const deviceAppStatus = (()=>{
    return liveData.current.activeMobileStatus?.installSummary?.status?.details || undefined;
  })();
  
  React.useEffect(()=>{
    mls.getApplicationData();
    mls.getDeviceMutationNeededForSession();
  });

  React.useEffect(()=>{
    if (liveData.current.activeMobileDeviceMutationNeededForSession !== undefined)
    {
      if (liveData.current.activeMobileDeviceMutationNeededForSession)
      {
        setShowConfirmDialog(true);
      }
      else
      {
        // No confirmation needed... we are ready to go
        setReadyToContinue(true);
      }
    }
    //eslint-disable-next-line
  },[liveData?.current?.activeMobileDeviceMutationNeededForSession]);

  React.useEffect(() => {
    if (
      deviceAppStatus === "Installed" && readyToContinue
    ) {
      liveData.mutate.setMode(liveData.current.modeEnums.READY_TO_LAUNCH);
    }
  }, [liveData, deviceAppStatus, readyToContinue]);

  useInterval(
    () => {
      mls.checkDeviceStatus();
    },
    2000,
    true
  );

  return (
    <>
      <CardContent {...params}>
        <div style={{ display: 'inline-flex' }}>
          <div style={{ float: 'left', textAlign: 'left', marginLeft: '0px' }}>
            <Typography variant="h6" component="h6">
              <div style={{ display: 'inline-flex' }}>
                {' '}
                {
                  liveData.current.launchOptionsWithIcons[
                    liveData.current.launchOptions.format
                  ]
                }{' '}
              </div>
            </Typography>
            <Typography variant="body1">
              <br />
              Configuring headset to run: "{liveData.current.launchInputs.contentName}"<br/>
              <InstallStatus contentName={liveData.current.launchInputs.contentName}/>
              <br />
            </Typography>
            <br />


                <Button
                  variant="contained"
                  color="default"
                  size="large"
                  style={{ width: '140px', marginTop: '10px', fontSize: '14px' }}
                  onClick={() => {
                    //setCurrentMode( GET_FORMAT );
                    liveData.mutate.setMode(
                      liveData.current.modeEnums.GET_FORMAT
                    );
                  }}
                >
                  <ArrowBackIcon size="small" style={{ marginRight: '10px' }} />{' '}
                  Back
                </Button>
          </div>
          <div style={{float:"right"}}>
          {liveData.current.activeMobileApplicationData?.mediaUrl?
          <DeviceImage src={liveData.current.activeMobileApplicationData.mediaUrl}
            style={{ marginLeft: '20px', alignSelf: 'flex-start', width: '50%' }}
          />
          :
          <DeviceImage
            style={{ marginLeft: '20px', alignSelf: 'flex-start', width: '50%' }}
          />}
          </div>
        </div>
        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Update Headset?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Configure the headset for {appName}?
              <br />              
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowConfirmDialog(false);
                liveData.mutate.setMode(
                  liveData.current.modeEnums.GET_FORMAT
                );
              }}
              color="primary"
            >
              No
            </Button>
            <Button
              onClick={() => {
                (async()=>{
                  try {
                    setShowConfirmDialog(false);
                    // Making the required changes to the device
                    await mls.mutateDeviceForSession();
                    setReadyToContinue(true);
                  }
                  catch(e)
                  {
                    console.log("Problem with mutation:",e);
                  }
                })();
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </>
  );
}
