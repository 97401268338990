import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import SharedDataContext from './SharedData.js';

import {
  DeviceImage,
  DesktopIcon,
  VRIcon,
  CloudIcon,
} from './CustomIcons.js';

export default function GetFormatCardContent(params) {

  const liveData = React.useContext(SharedDataContext);

  const handleFormatSelected = (f) => {
    liveData.mutate.setLaunchOptions({
      ...liveData.current.launchOptions,
      format: f,
    });
    //setCurrentMode( ESTABLISHING_CONNECTION )
    liveData.mutate.setMode(liveData.current.modeEnums.ESTABLISHING_CONNECTION);
  };

  return (
    <>
      <CardContent {...params}>
        <Paper style={{ textAlign: 'center', display: 'inline-flex' }}>
          <DeviceImage style={{ height: "20em" }} />
          <div>
            <Typography paragraph>Select lesson format to load</Typography>
            <MenuList>
              <Divider />
              <MenuItem
                divider
                disabled={
                  !liveData?.current?.launchInputs?.formats?.localDesktop ??
                  true
                }
                onClick={() => {
                  handleFormatSelected('localDesktop');
                }}
              >
                <DesktopIcon /> Local Desktop
              </MenuItem>
              <MenuItem
                divider
                disabled={
                  !liveData?.current?.launchInputs?.formats?.localVR ?? true
                }
                onClick={() => {
                  handleFormatSelected('localVR');
                }}
              >
                <VRIcon /> Local VR
              </MenuItem>
              <MenuItem
                divider
                disabled={
                  !liveData?.current?.launchInputs?.formats?.mobileVR ?? true
                }
                onClick={() => {
                  handleFormatSelected('mobileVR');
                }}
              >
                <VRIcon /> Mobile VR
              </MenuItem>
              <MenuItem
                divider
                disabled={
                  !liveData?.current?.launchInputs?.formats?.cloudStreaming ??
                  true
                }
                onClick={() => {
                  handleFormatSelected('cloudStreaming');
                }}
              >
                <CloudIcon /> Cloud Streaming
              </MenuItem>

              { liveData?.current?.launchInputs?.formats?.testLMS ?
              <MenuItem
                divider
                disabled={
                  !liveData?.current?.launchInputs?.formats?.testLMS ?? true
                }
                onClick={() => {
                  handleFormatSelected('testLMS');
                }}
              >
                <VRIcon /> Test LMS
              </MenuItem> : null}
            </MenuList>
          </div>
        </Paper>
      </CardContent>
    </>
  );
}
